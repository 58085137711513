import {
  Configuration,
  LogLevel,
  PublicClientApplication,
} from "@azure/msal-browser";

const msalConfig: Configuration = {
  auth: {
    clientId: "89bfaa55-0b72-4668-86a6-fb3f639b1742",
    // clientId: "63f1b4ac-d798-4b65-885d-2282978f670f",
    authority:
      "https://login.microsoftonline.com/cfd91e78-1609-48f1-9374-b98c578e3cdb",
   // redirectUri: "http://localhost:3000/account/login",
    redirectUri: "https://devportal.vitale.digital/account/login",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) return;
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
        }
      },
    },
  },
};
export const msalInstance = new PublicClientApplication(msalConfig);
